<template>
	<div>
		<h1 style="margin-left:0.15rem">Sorry</h1>
		<h5 style="color:#c03; margin: 0.15rem;">发生错误，请将当前页面截图发给技术人员！谢谢~</h5>
		<h4 style="margin-left:0.15rem">request-error</h4>
		<div class="code">
			<p>{{errMsg['request-error']}}</p>
		</div>
		<h4 style="margin-left:0.15rem">login-error</h4>
		<div class="code">
			<p>{{errMsg['login-error']}}</p>
		</div>
		<h4 style="margin-left:0.15rem">error-detail</h4>
		<div class="code">
			<p>{{errMsg['error-detail']}}</p>
		</div>
	</div>
</template>
<script babel>
	export default {
		data: ()=> ({
			errMsg: {
				'request-error': '',
				'login-error': '',
				'error-detail': ''
			}
		}),
		mounted: function() {
			this.$store.commit('updatePageTitle',{title: 'Error'})
			this.errMsg = 
				{
					'request-error': sessionStorage.getItem('request-error'),
					'login-error': sessionStorage.getItem('login-error'),
					'error-detail': sessionStorage.getItem('error')
				}
		}
	}
</script>
<style scoped>
.code {
	width: 6.9rem;
	color: #666;
	word-break: break-all;
	margin: 0.15rem;
	border-radius: 0.5rem;
	background-color: #f2f2f2;
	padding: 0.15rem;
}
</style>